import React from "react";
import Text from "../Text";
import BoxContainer from "../BoxContainer";
import textResolver from "../../core/utils/text-resolver";

function OfficesLocations({ data, background = "bg-prussian-blue" }) {
  const { dataType = 0 } = data;
  return (
    <BoxContainer background={background} className="xs:px-5 2xl:px-0 py-20">
      <div className="w-full max-w-6xl mx-auto">
        <Text
          as="h2"
          highlightedText={
            dataType === 1
              ? data.highlightWords
              : textResolver(data, "highlightWords")
          }
          highlightedColor={data.highlightedColor}
          className="text-3xl text-white text-center font-primary font-bold pb-12"
        >
          {dataType === 1 ? data.title : textResolver(data, "title")}
        </Text>
        <div className="grid 0px:grid-cols-1 md:grid-cols-3 0px:gap-20 md:gap-10 lg:gap-20 justify-items-center">
          {data.items.map((item, i) => {
            return (
              <div key={i} className="flex flex-col items-center">
                <div className="mb-6">
                  <img
                    src={dataType === 1 ? item.img : textResolver(item, "img")}
                    alt="location"
                    className="w-full max-w-full"
                  />
                </div>
                <Text
                  as="h3"
                  className="text-basexl text-white text-center font-primary font-semibold pb-2"
                >
                  {dataType === 1 ? item.title : textResolver(item, "title")}
                </Text>
                <Text
                  as="p"
                  className="text-base text-white text-center font-opensans"
                >
                  {dataType === 1
                    ? item.description
                    : textResolver(item, "description")}
                </Text>
              </div>
            );
          })}
        </div>
      </div>
    </BoxContainer>
  );
}
export default OfficesLocations;
