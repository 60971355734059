import React from "react";
import ContactUs from "./ContactUs";
import OfficesLocations from "../../components/OfficesLocation";
import Footer from "../Home/Footer";

import {
  legalTranscriptionHeaderdata,
  legalContactus,
  officesLocationData,
  legalTranscriptionFooterData,
} from "../utils/sample-data";

const Contact = () => {
  return (
    <>
      <ContactUs
        data={legalContactus}
        headerData={legalTranscriptionHeaderdata}
      />
      <OfficesLocations data={officesLocationData} />
      <Footer data={legalTranscriptionFooterData} />
    </>
  );
};
export default Contact;
