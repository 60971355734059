import React from "react";
import Contact from "../LegalTranscription/Contactus";
import Seo from "../components/seo";

function Index() {
  return (
    <>
      <Seo
        title="Contact Us - LexTranscribe"
        description="Interested in legal transcription? Get in touch with our team for a FREE price quote and straightforward answers to your questions. We’re happy to help you. "
        slug="/contactus"
      />
      <Contact />
    </>
  );
}
export default Index;
